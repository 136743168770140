<template>
  <div class="home">
    <section id="homehead">
      <h1 class="maintitle">CONTACT</h1>      
      <p class="maintext">Pour toute demande de devis ou d'informations, vous pouvez me contacter de préférence par mail ou sur mes différents réseaux : Malt, Facebook, Instagram et ComeUp. Le formulaire de contact ci-dessous est à votre disposition pour me lancer votre bouteille à la mer !</p>
    </section>
    <section id="anchor" class="contact">
      <h2 class="contact__title revealleft">FORMULAIRE DE CONTACT / DEMANDE DE DEVIS</h2>
     <Mail class="revealright"/>
      <!--<h2 class="contact__title revealleft">OÙ ME TROUVER</h2>
      <div class="contact__reseaux revealright">
       <a href="https://www.instagram.com/lmcreanum" alt="Instagram"><i class="fab fa-instagram contact__icon"></i></a>
        <a href="https://www.facebook.com/LMCreationsNumeriques" alt="Facebook"><i class="fab fa-facebook contact__icon"></i></a>
        <a href="https://www.malt.fr/profile/lauriemontagner" alt="Malt"><img src="../assets/maltlogo.webp" class="contact__icon" alt="Logo Malt"/></a>
        <a href="https://5euros.com/profil/lmcreanum" aria-label="5euros.com"  alt="5euros.com"><img src="../assets/5euroslogo.webp" class="contact__icon" alt="Logo 5euros"/></a>
      </div>-->
      <div class="contact__block">
        <div class="contact__block__left revealleft">
            <h2 class="contact__title contact__title__mid">OÙ ME TROUVER</h2>
            <div class="contact__reseaux">
              <a href="https://www.instagram.com/lmcreanum" alt="Instagram"><i class="fab fa-instagram contact__icon"></i></a>
              <a href="https://www.facebook.com/LMCreationsNumeriques" alt="Facebook"><i class="fab fa-facebook contact__icon"></i></a>
              <a href="https://www.malt.fr/profile/lauriemontagner" alt="Malt"><img src="../assets/maltlogo.webp" class="contact__icon" alt="Logo Malt"/></a>
            </div>
            <div class="contact__reseaux">
              <a href="https://comeup.com/profil/lmcreanum" aria-label="Comeup"  alt="Comeup"><img src="../assets/logo-comeup-jaune.webp" class="contact__icon" alt="Logo 5euros"/></a>
              <a href="https://www.youtube.com/channel/UC0E6ErWFHGiQo4QShu8qt5A" aria-label="YouTube"  alt="YouTube"><i class="fab fa-youtube contact__icon"></i></a>
              <a href="https://www.behance.net/lmcreanum/" aria-label="Behance"  alt="Behance"><i class="fab fa-behance contact__icon"></i></a>
            </div>
        </div>
        <div class="contact__block__right revealright">
          <h2 class="contact__title contact__title__mid">ET LE TELEPHONE ?</h2>
          <p>LM Créations Numériques est une entreprise no call 📵 Au vu de mes activités variées, il m’arrive de couper mon téléphone pour ne pas être dérangée par une sonnerie que ce soit pendant un tournage, en pleine réflexion sur du code ou totalement concentrée sur du montage. C’est pour cela que je préfère le contact par mail, afin d’être plus focus sur vos projets, et de consacrer un temps spécialement dédié à vous répondre rapidement. Un rendez-vous téléphonique, visio ou même dans la vraie vie sera tout à fait possible après ce premier contact écrit !</p>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import $ from 'jquery';
import Mail from '../components/mail.vue';

export default {
  name: 'Home',
  components: {
    Mail
  },
  mounted() {
        /**************** Apparition au scroll *************/
        var rafId = null;
        var delay = 200;
        var lTime = 0;

        function scroll() {
          var scrollTop = $(window).scrollTop();
          var height = $(window).height()
          var visibleTop = scrollTop + height;
          $('.revealleft').each(function() {
            var $t = $(this);
            if ($t.hasClass('reveal_visible')) { return; }
            var top = $t.offset().top;
            if (top <= visibleTop) {
              if (top + $t.height() < scrollTop) {
                $t.removeClass('reveal_pending').addClass('reveal_visible');
              } else {
                $t.addClass('reveal_pending');
                if (!rafId) requestAnimationFrame(reveal);  
              }
            }
          });
          $('.revealright').each(function() {
            var $t = $(this);
            if ($t.hasClass('reveal_visible')) { return; }
            var top = $t.offset().top;
            if (top <= visibleTop) {
              if (top + $t.height() < scrollTop) {
                $t.removeClass('reveal_pending').addClass('reveal_visible');
              } else {
                $t.addClass('reveal_pending');
                if (!rafId) requestAnimationFrame(reveal);  
              }
            }
          });
        }
        function reveal() {
          rafId = null;
          var now = performance.now();
          
          if (now - lTime > delay) {
            lTime = now;
            var $ts = $('.reveal_pending');
            $($ts.get(0)).removeClass('reveal_pending').addClass('reveal_visible');  
          }
          
          
          if ($('.reveal_pending').length >= 1) rafId = requestAnimationFrame(reveal);
        }

        $(scroll);
        $(window).scroll(scroll);
  }
}
</script>

<style lang="scss">
.maintitle {
  font-family: 'Yanone Kaffeesatz', sans-serif;
  font-size: 8vw;
  color: white;
  margin-bottom: 0;
  @media (max-width: 1440px) {
		font-size: 8vw;
	}
  @media (max-width: 1024px) {
		font-size: 10vw;
	}
  @media (max-width: 768px) {
		font-size: 10vw;
	}
  @media (max-width: 425px) {
		font-size: 10vw;
	}
  @media (max-width: 300px) {
		font-size: 10vw;
	}
}

#homehead {
  width: 80%;
  margin: auto;
  height: 73vh;
}

.maintext {
  font-family: 'Open Sans', sans-serif;
  color: white;
  width: 60%;
  border-top: 6px solid white;
  padding-top: 24px;
  @media (max-width: 1440px) {
		font-size: medium;
    width: 60%;
	}
  @media (max-width: 1024px) {
		font-size: medium;
    width: 80%;
	}
  @media (max-width: 768px) {
		font-size: small;
    width: 80%;
	}
  @media (max-width: 425px) {
		font-size: small;
    width: 80%;
	}
  @media (max-width: 300px) {
		font-size: small;
    width: 80%;
	}
}

.contact {
  width: 100%;
  background-image: url('../assets/svg-path3.svg');
  background-size: contain; 
  background-position-y: center;
  background-repeat: no-repeat;
  overflow: hidden;
  @media screen and (max-width: 980px){
            background-size: cover;
            }
  &__block {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding-bottom: 20px;
    justify-content: space-between;
    width: 90%;
    margin: auto;
    @media screen and (max-width: 1120px){
            width: 100%;
            margin: auto;
            margin-bottom: 20px;
            display: flex;
            flex-direction: column;
            }
    &__left {
      width: 35%;
      display: flex;
      flex-direction: column;
      padding-bottom: 20px;
      @media screen and (max-width: 1120px){
              width: 90%;
              margin: auto;
              margin-bottom: 20px;
              }
    }
    &__right {
      width: 60%;
      display: flex;
      flex-direction: column;
      padding-bottom: 20px;
      @media screen and (max-width: 1120px){
              width: 90%;
              margin: auto;
              margin-bottom: 20px;
              }
    }
  }
  &__upper {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin-bottom: 30px;
  }
  &__down {
    display: block;
  }
  &__text {
    width: 50%;
    max-width: 600px;
    font-size: smaller;
  }
  &__title {
    font-family: 'Yanone Kaffeesatz', sans-serif;
    font-size: 70px;
    margin: auto;
    margin-top: 0;
    margin-bottom: 30px;
    position: relative;
    width: 90%;
    &__mid {
      margin:0!important;
            width: 100%;
            
    }
    &::before {
      content: '';
    width: 100%;
    position: absolute;
    left: 0;
    top: calc( 50% - 1.5px );
    border-bottom: 30px solid #38e01fab;
    z-index: -1;
    }
    @media screen and (max-width: 1120px){
            margin-top: 60px;
            }
    &__right {
      font-family: 'Yanone Kaffeesatz', sans-serif;
    font-size: 70px;
    margin: auto;
    margin-top: 0;
    margin-bottom: 30px;
    position: relative;
    width: 900%;
    text-align: right;
    &::before {
      content: '';
    width: 100%;
    position: absolute;
    left: 0;
    top: calc( 50% - 1.5px );
    border-bottom: 30px solid #38e01fab;
    z-index: -1;
    }
    @media screen and (max-width: 980px){
            margin-top: 60px;
            }
    }
  }
  &__reseaux {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    margin-top: 20px;
    margin-left: -12%;
    @media screen and (max-width: 1120px){
            margin-left: 0;
            }
  }
}

.aboutbutton {
    border: 2px solid #386ed8;
    width: fit-content;
    padding: 15px 15px 12px;
    box-sizing: border-box;
    border-radius: 40px;
    font-family: 'Yanone Kaffeesatz';
    font-size: xx-large;
    transition: all 0.25s;
     &:hover {
            background-color: #386ed8;
            box-shadow: 0px 2px 5px 0px black;
            color: white!important;
            transform: scale(1.02);
        }
        &:focus {
            background-color: lighten($color: #386ed8, $amount: 30);
            box-shadow: 0px 2px 5px 0px black;
            color: white;
            transform: scale(1.02);
        }
  @media screen and (max-width: 980px){
            width: 100%;
            text-align: center;
            }
}

.aboutbuttonrea {
    width: fit-content;
    padding: 17px 15px 12px;
    box-sizing: border-box;
    border-radius: 40px;
    font-family: 'Yanone Kaffeesatz';
    font-size: xx-large;
    transition: all 0.25s;
    color: white;
    background: linear-gradient(335deg, rgba(28,9,224,1) -50%, rgba(56,224,31,1) 100%);
     &:hover {
            background: linear-gradient(335deg, rgba(28,9,224,1) 0%, rgba(56,224,31,1) 100%);
            box-shadow: 0px 2px 5px 0px black;
            color: white!important;
            transform: scale(1.02);
        }
        &:focus {
            background: linear-gradient(335deg, rgba(28,9,224,1) 0%, rgba(56,224,31,1) 100%);
            box-shadow: 0px 2px 5px 0px black;
            color: white;
            transform: scale(1.02);
        }
  @media screen and (max-width: 980px){
            width: 100%;
            text-align: center;
            }
}

.aboutbuttonline {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.fa-instagram {
  color: #c13584;
}

.fa-facebook {
    color: #3b5998;
}

.fa-youtube {
  color: red;
}

.fa-behance {
  color: blue;
}

.contact__icon {
    width: 50px;
    height: 50px;
    font-size: 50px;
  }

/********* Apparition au scroll */

.revealleft {
  opacity: 0;
  transform: translate3d(-100px, 0, 0);
  transition: opacity 0.8s, transform 0.8s;
}

.revealright {
  opacity: 0;
  transform: translate3d(100px, 0, 0);
  transition: opacity 0.8s, transform 0.8s;
}

.reveal_visible {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
</style>
