<template>
<div>
   <div>
      </div>
       <section>
        <form id="contactform" class="contactform" method="post" @submit="onSubmit">
                <div class="form-group">
                    <label class="form-label" for="nom">Nom ou Société*</label>
                    <input class="form-control" pattern="[A-Za-zÀ-ÖØ-öø-ÿ' ._-]+$" minlength="2" maxlength="40" type="text" id="nom" required placeholder="Slater">
                </div>
                <div class="form-group">
                    <label class="form-label" for="prenom">Prénom</label>
                    <input class="form-control" pattern="[A-Za-zÀ-ÖØ-öø-ÿ' ._-]+$" minlength="2" maxlength="40" type="text" id="prenom" placeholder="Kelly">
                </div>
                <div class="form-group">
                    <label class="form-label" for="email">Email*</label>
                    <input class="form-control" type="email" minlength="2" maxlength="40" id="email" required placeholder="brice@denice.fr">
                </div>
                <div class="form-group">
                    <label class="form-label" for="tel">Téléphone</label>
                    <input class="form-control" pattern="[0-9 .+]+$" minlength="10" maxlength="14" type="text" id="tel" placeholder="0123456789">
                </div>
                <div class="form-group">
                    <label class="form-label" for="demande">Objet de votre demande*</label>
                    <select class="form-control" name="demande" id="demande" required>
                        <option value="dog">Création site web</option>
                        <option value="cat">Production vidéo</option>
                        <option value="hamster">Création de contenu</option>
                        <option value="parrot">Autre</option>
                    </select>
                </div>
                <div class="form-group">
                    <label class="form-label" for="message">Votre message*</label>
                <textarea class="form-control" name="message" minlength="2" maxlength="500" rows="6" id="message" required placeholder="Avant de jeter votre bouteille à la mer, pensez à préciser votre besoin, vos envies, votre budget... et tout ce qui vous semble utile afin que je puisse au mieux vous accompagner dans l'océan d'internet !"></textarea>
            </div>
        <input class="form__button" type="submit">
    </form>
       </section>
       <transition name="fade" mode="out-in">
          <div class="successmessage" v-if="sent">
              <p>Votre bouteille à la mer a bien été envoyée !</p>
          </div>
          <div class="errormessage" v-if="error">
              <p>Une erreur est survenue, merci de rééssayer ou de m'envoyer un mail à <a href="mailto:contact@lmcreationsnumeriques.fr">contact@lmcreationsnumeriques.fr</a></p>
          </div>
      </transition>
   </div>
</template>

<script>
//const qs = require("querystring");
import axios from 'axios';

export default {
    data() {
        return {
          sent: false,
          error: false
        };
    },
    methods: {
      onSubmit(e) {
          e.preventDefault();
            const nom = document.getElementById("nom").value; // récupération du nom dans l'input d'id nom
            const prenom = document.getElementById("prenom").value; // récupération du prenom dans l'input d'id prenom
            const tel = document.getElementById("tel").value; // récupération du mot de passe dans l'input d'id password
            const email = document.getElementById("email").value;
            const message = document.getElementById("message").value;

            var selectedList = [],
                selectBox = document.getElementById("demande"),
                i;

              for (i=0; i < selectBox.options.length; i++) 
              {
              if (selectBox.options[i].selected) 
              {
                let demande = selectBox.options[i].text;
                selectedList.push(demande);
              }
              }

            const formData = new FormData(); 
 
            formData.append('nom', nom); 
            formData.append('prenom', prenom); 
            formData.append('tel', tel); 
            formData.append('email', email); 
            formData.append('message', message); 
            formData.append('demande', selectedList); 

            axios.post("mail.php", formData,
                        {
                            headers: { // headers de la requete
                                'Content-Type': 'multipart/form-data'
                            }
                        }
             )
             .then(res => {
                  if(res.status === 200) { // Requete réussie
                            this.sent = true;
                 console.log(res);
                 document.getElementById("contactform").reset();
                        }
                    })
             .catch(res => {
                        this.error = true;
                        console.log(res);
                        
             });
      }
    }
};
</script>

<style lang="scss">

.contactform {
  width: 60%;
  margin: auto;
  overflow: hidden;
  @media screen and (max-width: 979px) {
      width: 90%; }
}

.errormessage {
  text-align: center;
  margin-top: 10px; }

  .successmessage {
  text-align: center;
  margin-top: 10px; }

.loginForm {
  display: block;
  margin: auto;
  width: 80%;
  max-width: 200px; }

.form-control {
  display: block;
  border-radius: 5px;
  border: 1px solid #386ed8;
  margin-bottom: 10px;
  width: 100%;
  padding: 3px;
  box-sizing: border-box; }

.form-label {
  display: block;
  margin-bottom: 5px;
  text-align: left; }

.form__button {
  text-decoration: none;
  background-color: #386ed8;
  padding: 5px;
  margin-top: 20px;
  border-radius: 5px;
  color: white;
  font-weight: bolder;
  transition: all 250ms;
  width: 100%;
  border: none; 
  margin-bottom: 30px;}
  .form__button:hover {
    background-color: #628adb;
    box-shadow: 0px 0px 4px 0px #6f7070; }

</style>